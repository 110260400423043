export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const API_SSR_BASE_URL = process.env.REACT_APP_CLUSTER_API_BASE_URL;
export const API_AUTH_URL = 'api/auth/v1/';
export const API_CORE_URL = 'api/core/v1/';
export const API_PAYMENT_URL = 'api/payments/v1/';
export const API_NOTIFICATON_URL = 'api/notifications/v1/';
export const API_DEVICES_URL = 'api/devices/v1/';
export const API_NEWSLETTER_URL = 'api/newsletter/v1/';
export const API_CV_URL = 'api/cv/v1/';
export const API_COMPANY_URL = 'api/companies/v1/';
export const API_RECRUITMENT_URL = 'api/recruitments/v1/';
export const API_COVERLETTER_URL = 'api/cover-letters/v1/';
export const API_CONSULTATION_URL = 'api/consultations/v1/';
export const API_ACTIVITIES_URL = 'api/activities/v1/';