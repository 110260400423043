import { useState, useEffect } from 'react';
import React from 'react';

import classes from './ImageChanger.module.scss';

const ImageChanger = ({ firstImage, secondImage }) => {
    const [showImage, setShowImage] = useState();
    const [timerId, setTimerId] = useState(null);
    const [isFirst, setIsFirst] = useState(true);
    useEffect(() => {
        setIsFirst(true);
        if (timerId) {
            clearTimeout(timerId); // Clear any existing timeout
        }
        setShowImage(firstImage);
        const timer = setTimeout(() => {
            setShowImage(secondImage);
            setIsFirst(false);
        }, 1000);
        setTimerId(timer);
    }, [firstImage]);

    return (
        <div className={classes.wrapper}>
            <img
                className={`${classes.image} `}
                src={firstImage}
                alt='feature image'
            />
            {!isFirst && (
                <img
                    className={`${classes.image} ${classes.second} ${classes.animation}`}
                    src={secondImage}
                    alt='feature image'
                />
            )}

        </div>
    );
};

export default ImageChanger;

{/* <img
className={`${classes.image} ${classes.second} ${!isFirst ? classes.animation : ''}`}
src={showImage}
alt='feature image'
/> */}