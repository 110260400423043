
import CourseImage from 'assets/images/pages/homepage/course-feature.png';
import CourseSecondImage from 'assets/images/pages/homepage/course-feature-2.png';
import CertificatesImage from 'assets/images/pages/homepage/certificate-feature.png';
import CertificatesSecondImage from 'assets/images/pages/homepage/certificate-feature-2.png';
import ResumeImage from 'assets/images/pages/homepage/resume-feature.png';
import ResumeSecondImage from 'assets/images/pages/homepage/resume-feature-2.png';
import CoverLetterImage from 'assets/images/pages/homepage/cover-letter-feature.png';
import CoverLetterScondImage from 'assets/images/pages/homepage/cover-letter-feature-2.png';
import SkillTreeImage from 'assets/images/pages/homepage/skill-tree-feature.png';
import SkillTreeSecondImage from 'assets/images/pages/homepage/skill-tree-feature-2.png';
import ConsultationImage from 'assets/images/pages/homepage/consultation-feature.png';
import ConsultationSecondImage from 'assets/images/pages/homepage/consultation-feature-2.png';

import CourseMobileImage from 'assets/images/pages/homepage/course-feature-mobile.png';
import CourseSecondMobileImage from 'assets/images/pages/homepage/course-feature-2-mobile.png';
import CertificatesMobileImage from 'assets/images/pages/homepage/certificate-feature-mobile.png';
import CertificatesSecondMobileImage from 'assets/images/pages/homepage/certificate-feature-2-mobile.png';
import SkillTreeMobileImage from 'assets/images/pages/homepage/skill-tree-feature-mobile.png';
import SkillTreeSecondMobileImage from 'assets/images/pages/homepage/skill-tree-feature-2-mobile.png';
import ResumeMobileImage from 'assets/images/pages/homepage/resume-feature-mobile.png';
import ResumeSecondMobileImage from 'assets/images/pages/homepage/resume-feature-2-mobile.png';
import CoverLetterMobileImage from 'assets/images/pages/homepage/cover-letter-feature-mobile.png';
import CoverLetterScondMobileImage from 'assets/images/pages/homepage/cover-letter-feature-2-mobile.png';
import ConsultationMobileImage from 'assets/images/pages/homepage/consultation-feature-mobile.png';
import ConsultationSecondMobileImage from 'assets/images/pages/homepage/consultation-feature-2-mobile.png';

export const KnowPharmuniItems = [
    {
        id: 'skill_tree',
        title: 'Build Your Pharma Skillset',
        text: `Take control of your career with Pharmuni’s Skill Tree.
         Easily map out your strengths, pinpoint gaps, and set clear goals—backed by AI insights.
          Discover recommended courses in QA, QC, and Regulatory Affairs, then watch your skills grow as you progress.
         Empower your professional journey and unlock new opportunities in the pharmaceutical industry.`,
        image: SkillTreeImage.src,
        secondImage: SkillTreeSecondImage.src,
        mobileImage: SkillTreeMobileImage.src,
        secondMobileImage: SkillTreeSecondMobileImage.src,
        link: 'https://pharmuni.com/skill-tree/'
    },
    {
        id: 'courses',
        title: 'Elevate Your Pharma Skills',
        text: `Explore a wide range of pharma-focused courses—from quality control and regulatory affairs to
         marketing and IT—designed by TUV ISO 9001-certified Zamann Pharma Support.
         Whether you’re new or seasoned, each course ends with a quiz to reinforce learning.
         Pass it, and you’ll earn an industry-recognized certificate to showcase your expertise.`,
        image: CourseImage.src,
        secondImage: CourseSecondImage.src,
        mobileImage: CourseMobileImage.src,
        secondMobileImage: CourseSecondMobileImage.src,
        link: '/pharmuni-courses/'
    },
    {
        id: 'certificates',
        title: 'Earn Industry-Recognized Certificates',
        text: `Validate your expertise with certificates developed by TÜV ISO 9001-certified Zamann Pharma Support.
         Each course aligns with current industry standards, ensuring global recognition.
         Pass the quiz and receive a unique, verifiable certificate to showcase your credibility and open 
         doors in the fast-growing pharmaceutical field.`,
        image: CertificatesImage.src,
        secondImage: CertificatesSecondImage.src,
        mobileImage: CertificatesMobileImage.src,
        secondMobileImage: CertificatesSecondMobileImage.src,
        link: 'https://pharmuni.com/pharmuni-certificate/'
    },
    {
        id: 'resume',
        title: 'Craft a Standout Pharma Resume',
        text: `Create a polished, industry-specific resume in minutes with Pharmuni’s 
         user-friendly Resume Builder. Choose from professional templates geared toward 
         roles in QA, regulatory, research, and beyond—no design skills needed. Showcase your expertise,
         download or share your CV seamlessly, and open doors to new opportunities in the pharmaceutical world.`,
        image: ResumeImage.src,
        secondImage: ResumeSecondImage.src,
        mobileImage: ResumeMobileImage.src,
        secondMobileImage: ResumeSecondMobileImage.src,
        link: 'https://pharmuni.com/resume-builder/'
    },
    {
        id: 'cover_letter',
        title: 'Craft a Pharma-Focused Cover Letter',
        text: `Elevate your job application with a cover letter tailored to the pharmaceutical sector.
         Our user-friendly builder helps you highlight your unique skills, achievements,
         and passion—all in a polished, professional format. Stand out from the crowd in minutes, 
         and pair it with our Resume Builder for a seamless, standout application package.`,
        image: CoverLetterImage.src,
        secondImage: CoverLetterScondImage.src,
        mobileImage: CoverLetterMobileImage.src,
        secondMobileImage: CoverLetterScondMobileImage.src,
        link: 'https://pharmuni.com/cover-letter-builder/'
    },
    {
        id: 'consultation',
        title: 'Free Online Consultation with Pharma Experts',
        text: `Enjoy a dedicated online session—free for Pharmuni subscribers—with seasoned pharmaceutical 
        professionals. Use this personalized consultation to refine your interview skills, explore job 
        immigration pathways, and get tailored career advice for the fast-paced pharma industry. 
        Take charge of your future with expert insights that help you stand out and succeed.`,
        image: ConsultationImage.src,
        secondImage: ConsultationSecondImage.src,
        mobileImage: ConsultationMobileImage.src,
        secondMobileImage: ConsultationSecondMobileImage.src,
        link: 'https://pharmuni.com/vip-consultation-meeting'
    },
]