import React from 'react';

import { useMutation } from 'react-query';
import { UtmActivity } from 'services/activities';

const UseActivities = () => {
    const { mutate } = useMutation(UtmActivity, {
        onSuccess: () => {
            localStorage.removeItem('UTM');
        },
        onError: () => {
            localStorage.removeItem('UTM');
        }
    });

    const sendUTM = () => {
        if (localStorage.getItem('UTM') !== null) {
            const UTM = JSON.parse(localStorage.getItem('UTM'));
            if ('utm_source' in UTM)
                mutate(UTM);
        }
    };
    return { sendUTM };
};

export default UseActivities;