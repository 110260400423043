import { useEffect } from 'react';
import { useRouter } from 'next/router';

import UseActivities from 'hooks/use-activities';

import useDeviceIdentify from './use-deviceidentify';
import { isUserLoggedIn } from 'utils/authentication';

import useFcmToken from './use-fcmtoken';

const UseVisitor = () => {
	const router = useRouter();
	const { sendUTM } = UseActivities();
	const { handleDeviceIdentification } = useDeviceIdentify();

	const { FetchFcmToken, retrieveToken } = useFcmToken();
	const fcmToken = retrieveToken();

	const isWindowAvailable = typeof window !== undefined;

	useEffect(() => {
		if (!fcmToken) {
			FetchFcmToken();
		}
	}, [isWindowAvailable]);

	useEffect(() => {
		if (fcmToken) {
			const deviceId = localStorage.getItem('deviceId');
			const deviceHasBeenIdentified = localStorage.getItem('deviceHasBeenIdentified');
			if (!deviceId) {
				if (typeof navigator !== 'undefined' && navigator) {
					const { DeviceUUID } = require('device-uuid');
					const uuid = new DeviceUUID().get();
					if (uuid) {
						localStorage.setItem('deviceId', uuid);
					}
				}
			}
			if (!deviceHasBeenIdentified) {
				handleDeviceIdentification();
			}
		}
	}, [fcmToken]);

	useEffect(() => {
		const { query } = router;
		const hasUTM = 'utm_source' in query;
		const isAuthenticated = isUserLoggedIn();
		if (hasUTM) {
			localStorage.setItem('UTM', JSON.stringify(query));
			if (isUserLoggedIn())
				sendUTM();
		}


	}, [router.isReady]);



};

export default UseVisitor;
