import React, { useState, useEffect, useRef } from 'react';

import { useRouter } from 'next/router';

import { useQuery } from 'react-query';
import { SearchSuggestService } from 'services/core';

import SearchSuggestDropdown from 'components/common/search/searchsuggestdropdown/SearchSuggestDropdown';

import classes from './SerachSuggest.module.scss';

import SearchIcon from '@core/assets/icons/search-normal.svg';

const SearchSuggest = ({ className, type }) => {
	const router = useRouter();
	const [searchKeyword, setSearchKeyword] = useState('');
	const [showResult, setShowResult] = useState(false);
	const { data, refetch, remove } = useQuery(
		'search',
		() => SearchSuggestService({ keyword: searchKeyword, page_size: 5 }),
		{
			enabled: false
		}
	);
	// send ref to dropdown to prevent close on click
	const searchSuggestRef = useRef();

	useEffect(() => {
		// remove chached data
		searchKeyword === '' && remove();
		// debounce
		const searchDebounce = setTimeout(() => {
			if (type === 'mobile') {
				if (searchKeyword) {
					const { page, ...queryParams} = router.query;
					if (router.pathname !== '/explore') {
						router.push({
							pathname: `/explore`,
							query: {
								search_keyword: searchKeyword
							},
							state: { from: router.pathname }
						});
					} else {
						router.replace(
							{
								pathname: `/explore`,
								query: {
									...queryParams,
									search_keyword: searchKeyword
								},
								state: { from: router.pathname }
							},
							undefined,
							{ shallow: true }
						);
					}
				}
			} else {
				searchKeyword && refetch();
				searchKeyword && setShowResult(true);
			}
		}, 2000);
		return () => {
			clearTimeout(searchDebounce);
		};
	}, [searchKeyword, refetch]);

	useEffect(() => {
		const { search_keyword } = router.query;
		if (search_keyword) {
		  setSearchKeyword(search_keyword);
		}
	  }, [router.query]); 
	// if there is data show results
	const inputFocusHandler = () => {
		data && data.data?.results?.length && setShowResult(true);
		// type === 'mobile' && setShowResult(true);
	};
	// navigate user to searchpage after submit
	const searchSubmitHandler = (e) => {
		e.preventDefault();
		setSearchKeyword('');
		const { page, ...queryParams} = router.query;
		if (router.pathname !== '/explore') {
			router.push({
				pathname: `/explore`,
				query: {
					search_keyword: searchKeyword
				},
				state: { from: router.pathname }
			});
		} else {
			router.replace(
				{
					pathname: `/explore`,
					query: {
						...queryParams,
						search_keyword: searchKeyword
					},
					state: { from: router.pathname }
				},
				undefined,
				{ shallow: true }
			);
		}
	};

	return (
		<div className={`${className}`} ref={searchSuggestRef}>
			<form
				onSubmit={searchSubmitHandler}
				className={`${classes['search-suggest-wrapper']} d-flex  align-items-center`}>
				<button type="submit">
					<SearchIcon role="img" />
				</button>
				<input
					name="searchKeyword"
					value={searchKeyword}
					onFocus={inputFocusHandler}
					onChange={(e) => setSearchKeyword(e.target.value)}
					type="search"
					role="searchbox"
					placeholder="Search courses"
					autoComplete={type === 'mobile' ? 'off' : 'on'}
				/>
				{type === 'desktop' && showResult && data && (
					<div className={`${classes['search-suggest-dropdown']}`}>
						<SearchSuggestDropdown
							searchKeyword={searchKeyword}
							setShowResult={setShowResult}
							items={data.data.results}
							forwardedRef={searchSuggestRef}
						/>
					</div>
				)}
				{/* {type === 'mobile' && showResult && (
					<div className={`${classes['search-suggest-result']}`}>
						<SearchSuggestResult
							searchKeyword={searchKeyword}
							setSearchKeyword={setSearchKeyword}
							setShowResult={setShowResult}
							items={data?.data?.results}
						/>
					</div>
				)} */}
			</form>
		</div>
	);
};

export default SearchSuggest;
